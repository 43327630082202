exports.components = {
  "component---src-articles-article-1-mdx": () => import("./../../../src/articles/article1.mdx" /* webpackChunkName: "component---src-articles-article-1-mdx" */),
  "component---src-articles-article-2-mdx": () => import("./../../../src/articles/article2.mdx" /* webpackChunkName: "component---src-articles-article-2-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-cosmetiques-mdx": () => import("./../../../src/pages/cosmetiques.mdx" /* webpackChunkName: "component---src-pages-cosmetiques-mdx" */),
  "component---src-pages-creations-produits-mdx": () => import("./../../../src/pages/creations-produits.mdx" /* webpackChunkName: "component---src-pages-creations-produits-mdx" */),
  "component---src-pages-entreprise-mdx": () => import("./../../../src/pages/entreprise.mdx" /* webpackChunkName: "component---src-pages-entreprise-mdx" */),
  "component---src-pages-hygiene-mdx": () => import("./../../../src/pages/hygiene.mdx" /* webpackChunkName: "component---src-pages-hygiene-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mentions-legales-mdx": () => import("./../../../src/pages/mentions-legales.mdx" /* webpackChunkName: "component---src-pages-mentions-legales-mdx" */),
  "component---src-pages-nos-marches-mdx": () => import("./../../../src/pages/nos-marches.mdx" /* webpackChunkName: "component---src-pages-nos-marches-mdx" */),
  "component---src-pages-nutrition-mdx": () => import("./../../../src/pages/nutrition.mdx" /* webpackChunkName: "component---src-pages-nutrition-mdx" */)
}

